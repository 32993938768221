/** @jsx jsx */

import { HTMLAttributes } from 'react';

import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { ReckonTheme, useTheme } from '@reckon-web/theme';
import { makeId } from '@reckon-web/utils';

import { Column, SortType } from './DataTable';

export type GapType = keyof ReckonTheme['spacing'];
export type SeparationType = 'stripes' | 'dividers';

// <table />
// ------------------------------

export const TableElement = (props: HTMLAttributes<HTMLTableElement>) => (
  <table
    css={{
      borderSpacing: 0,
      width: '100%',
    }}
    {...props}
  />
);

// <colgroup />
// ------------------------------

export const TableColGroup: React.FC<{
  cols: { [key: string]: Column };
}> = ({ cols }) => (
  <colgroup>
    {Object.keys(cols).map((col: string) => (
      <col key={col} width={cols[col].width} />
    ))}
  </colgroup>
);

// <thead />
// ------------------------------

export const TableHead: React.FC = ({ children }) => (
  <thead children={children} />
);

// <tfoot />
// ------------------------------

export const TableFoot: React.FC = ({ children }) => (
  <tfoot children={children} />
);

// <tbody />
// ------------------------------

export const TableBody: React.FC = ({ children }) => (
  <tbody children={children} />
);

// <tr />
// ------------------------------

export const TableRow: React.FC = ({ children }) => <tr children={children} />;

// <th /> | <td />
// ------------------------------

type TableCellProps = {
  align?: 'left' | 'center' | 'right';
  gap: GapType;
  isFoot?: boolean;
  isHead?: boolean;
  separation?: SeparationType;
} & HTMLAttributes<HTMLTableCellElement>;

export const TableCell = ({
  align = 'left',
  gap,
  isHead,
  isFoot,
  separation,
  ...props
}: TableCellProps) => {
  const { palette, spacing } = useTheme();

  const Tag = isHead ? 'th' : 'td';

  // pad the cell: enforce vertical padding on header cells
  const pad = spacing[gap];
  const paddingX = pad;
  const paddingY = isHead ? spacing.medium : pad;

  const separationMap = {
    dividers: {
      'tr:not(:last-child) > &': {
        borderBottom: `1px solid ${palette.global.border}`,
      },
    },
    stripes: {
      'tr:nth-of-type(odd) > &': {
        backgroundColor:
          !isHead && !isFoot ? palette.background.muted : undefined,
      },
    },
  } as const;
  const separationStyles = separation ? separationMap[separation] : {};

  const stickyStyles = isHead
    ? ({
        position: 'sticky',
        top: 0,
        zIndex: 1,

        // NOTE: fix for cross-browser support
        // Table elements are strange beasts that don't always play well with CSS
        '&::before': {
          boxShadow: `0 1px 0 ${palette.global.border}`,
          content: '" "',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
      } as const)
    : undefined;

  const footStyles = isFoot
    ? {
        boxShadow: `0 -1px 0 ${palette.global.border}`,
      }
    : {};

  return (
    <Tag
      css={{
        backgroundColor: palette.background.base,
        padding: `${paddingY}px ${paddingX}px`,
        textAlign: align,
        verticalAlign: 'middle',

        ...separationStyles,
        ...stickyStyles,
        ...footStyles,
      }}
      {...props}
    />
  );
};

const flexTextAlignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;

type TableHeadColumnProps = {
  columnKey: string;
  columnMeta: Column;
  gap: GapType;
  onSortChange?: (value: SortType | undefined) => void;
  sort?: SortType;
} & HTMLAttributes<HTMLTableCellElement>;

export const TableHeadColumn = ({
  columnKey,
  columnMeta,
  gap,
  id,
  onSortChange,
  sort,
  ...props
}: TableHeadColumnProps) => {
  const { palette } = useTheme();
  const label =
    typeof columnMeta.label === 'function'
      ? columnMeta.label(sort)
      : columnMeta.label;
  const labelElement = (
    <Text color="muted" size="xsmall">
      {label}
    </Text>
  );

  // observe consumer sort preference. default to allow sorting, IF there's a label
  const allowSort =
    columnMeta.allowSort !== undefined ? columnMeta.allowSort : Boolean(label);

  const sortBaseStyles = {
    alignItems: 'center',
    background: 0,
    border: 0,
    color: palette.text.muted,
    display: 'flex',
    justifyContent: flexTextAlignment[columnMeta.align || 'left'],
    padding: `1px 0`, // resolve weird bug in Chrome that hides the box shadow on the table cell
    position: 'relative',
    width: '100%',
  } as const;

  return (
    <TableCell isHead gap={gap} align={columnMeta.align} id={id} {...props}>
      {sort && onSortChange && allowSort ? (
        <button
          id={makeId(id, 'sort-button')}
          type="button"
          css={[
            sortBaseStyles,
            {
              cursor: 'pointer',

              ':hover, &.focus-visible': {
                color: palette.text.base,

                '[data-order-indicator]': {
                  opacity: 1,
                },
              },

              ':focus': {
                outline: 0,
              },

              '&.focus-visible': {
                outline: `2px solid ${palette.global.focusRing}`,
                outlineOffset: 2,
              },

              // increase hit-area
              '::before': {
                content: '" "',
                position: 'absolute',
                left: -8,
                bottom: -8,
                top: -8,
                right: -8,
              },
            },
          ]}
          onClick={() => {
            onSortChange({
              key: columnKey,
              asc: columnKey === sort.key ? !sort.asc : true,
            });
          }}
        >
          {labelElement}
          <OrderByIndicator asc={sort.asc} active={columnKey === sort.key} />
        </button>
      ) : (
        <div style={sortBaseStyles}>{labelElement}</div>
      )}
    </TableCell>
  );
};

const OrderByIndicator = ({
  asc,
  active,
}: {
  asc: boolean;
  active: boolean;
}) => {
  return (
    <span
      data-order-indicator="true"
      css={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: 16,

        // show/hide using opacity rather than forked render or `display: none`
        // because we want the indicator to always take up the same amount of
        // space; avoids the columns jumping around
        opacity: active ? 1 : 0.6,
      }}
    >
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity={active && asc ? 1 : 0.4}
          d="M7.59983 7.66663H0.399874C0.248275 7.66663 0.109876 7.75223 0.041876 7.88782C-0.0253236 8.02342 -0.0109237 8.18542 0.0798758 8.30662L3.67986 13.1066C3.75506 13.2074 3.87386 13.2666 3.99985 13.2666C4.12585 13.2666 4.24425 13.2074 4.31985 13.1066L7.91983 8.30662C8.01103 8.18542 8.02543 8.02342 7.95783 7.88782C7.88983 7.75223 7.75143 7.66663 7.59983 7.66663Z"
        />
        <path
          opacity={active && !asc ? 1 : 0.4}
          d="M7.59983 6.33337H0.399874C0.248275 6.33337 0.109876 6.24777 0.041876 6.11218C-0.0253236 5.97658 -0.0109237 5.81458 0.0798758 5.69338L3.67986 0.893404C3.75506 0.792604 3.87386 0.733405 3.99985 0.733405C4.12585 0.733405 4.24425 0.792604 4.31985 0.893404L7.91983 5.69338C8.01103 5.81458 8.02543 5.97658 7.95783 6.11218C7.88983 6.24777 7.75143 6.33337 7.59983 6.33337Z"
        />
      </svg>
    </span>
  );
};

// Empty state
// ------------------------------

export const EmptyState = (props: HTMLAttributes<HTMLDivElement>) => {
  const { palette } = useTheme();
  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: palette.background.dim,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 240,
        textAlign: 'center',
      }}
      {...props}
    />
  );
};
